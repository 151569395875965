import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'

const About = () => {
  return (
    <Box sx={{ maxWidth: "300px" }}>
      <Typography sx={{ mt: 4, mb: "19px" }}>
        <img src={process.env.PUBLIC_URL + "logo1.png"} alt="log" />
      </Typography>
      <Typography variant="body2" sx={{ mb: "37px", fontSize: "16px" }}>
        Nam posuere accumsan porta. Integer id orci sed ante tincidunt tincidunt sit amet sed libero.
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontSize: "16px" }}>
        © StorageConnect 2022, All Rights Reserved
      </Typography>
    </Box>
  )
}

export default About

import React from 'react'
import {
  Box,
  Typography,
  Container,
  Stack
} from '@mui/material'
import { MButton } from 'components/CustomMaterial'
import { SmallCard } from 'components/SmallCard'
import { useHistory } from 'react-router-dom'

const cardItems = [
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card1.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5hrs ago',
    status: 'Buy',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card2.png'}`,
    briefContent: 'Learn Everything about managing and operating a Self Storage',
    time: '2days ago',
    status: 'Operate',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card3.png'}`,
    briefContent: 'Everything you need to know about selling a Self Storage',
    time: '3days ago',
    status: 'Sell',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card4.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5days ago',
    status: 'Buy',
    name: 'Raza'
  }
]

const LearnEverything = () => {
  const history = useHistory()

  return (
    <Box sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/everythingBg.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#1849C6',
      borderTopLeftRadius: '300px',
      position: 'relative',
      bottom: '190px',
      backgroundPositionY: '180px',
      paddingBottom: '108px'
    }}>
      <img style={{
        position: 'absolute',
        bottom: '112px',
      }} src={process.env.PUBLIC_URL + "images/ellipse1.png"} alt="ellipse" />
      <img style={{
        position: 'absolute',
        top: '164px',
        right: 0
      }} src={process.env.PUBLIC_URL + "images/ellipse2.png"} alt="ellipse" />
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{
            pt: '101px !important',
            fontSize: '35px',
            fontWeight: '600',
            color: '#FFFFFF',
            lineHeight: '50px',
            mb: '20px'
          }}>
            Learn Everything About Buying, Selling, and Operating a Self Storage
          </Typography>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '30px',
            color: '#FFFFFF',
            padding: '0px 200px'
          }}>
            Learn about literally anything to know about Selling or Buying a Self Storage
          </Typography>
        </Box>
      </Container>

      <Container maxWidth="xl" sx={{ mt: '73px' }}>
        <Stack direction="row" justifyContent="space-around">
          {
            cardItems.map((item, key) => (
              <SmallCard
                cardImageURL={item.cardImageURL}
                briefContent={item.briefContent}
                time={item.time}
                status={item.status}
                name={item.name}
                key={key}
              />
            ))
          }
        </Stack>
      </Container>
      <Container sx={{ textAlign: 'center' }}>
        <MButton
          variant="contained"
          sx={{
            backgroundColor: '#FFCE31',
            color: '#000103B2',
            fontSize: '20px',
            fontWeight: '600 !important',
            width: '364px',
            height: '80px',
            marginTop: '100px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#FFCE31 !important',
              color: '#000103B2 !important',
            },
            '&:active': {
              backgroundColor: '#FFCE31 !important',
              color: '#000103B2 !important',
            },
            '&:focus': {
              backgroundColor: '#FFCE31 !important',
              color: '#000103B2 !important',
            }
          }}
          onClick={() => history.push('learn')}
        >
          Explore the Learning Section
        </MButton>
      </Container>
    </Box>
  )
}

export default LearnEverything

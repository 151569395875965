import React, { useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment
} from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'

const GetSocial = () => {
  const [language, setLanguage] = useState('English')
  const handleChange = (event) => {
    setLanguage(event.target.value)
  }

  return (
    <Box>
      <Typography sx={{ mt: 4, mb: "22px", fontSize: "18px" }} variant="h6" component="div">
        LET'S GET SOCIAL
      </Typography>

      <Stack direction="row" spacing="2">
        <IconButton>
          <img src={process.env.PUBLIC_URL + "images/facebook.png"} alt="log" />
        </IconButton>
        <IconButton>
          <img src={process.env.PUBLIC_URL + "images/linkin.png"} alt="log" />
        </IconButton>
        <IconButton>
          <img src={process.env.PUBLIC_URL + "images/twitter.png"} alt="log" />
        </IconButton>
        <IconButton>
          <img src={process.env.PUBLIC_URL + "images/instagram.png"} alt="log" />
        </IconButton>
      </Stack>

      <FormControl sx={{ m: 1, width: "25ch" }} size="small">
        <InputLabel id="demo-select-small">language</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          label="Language"
          disableUnderline
          value={language}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <PublicIcon />
            </InputAdornment>
          }
        >
          <MenuItem value="English">English</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default GetSocial

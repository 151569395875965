import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  root: {

  },
  rectangle1: {
    transform: "rotate(45deg)",
    width: "191px",
    height: "191px",
    position: "absolute",
    left: "2.4%",
    bottom: "2%",
    background: "#1849C6",
    mixBlendMode: "normal",
    opacity: "0.05",
    borderRadius: "18px",
  },
  rectangle2: {
    transform: "rotate(45deg)",
    width: "255px",
    height: "255px",
    position: "absolute",
    left: "5.5%",
    bottom: "-30.94%",
    background: "#1849C6",
    mixBlendMode: "normal",
    opacity: "0.05",
    borderRadius: "30px",
  },
}))

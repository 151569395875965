import React from 'react'
import {
  Box,
  Container,
  Stack,
  Typography
} from '@mui/material'
import { MButton } from 'components/CustomMaterial'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { SmallCard } from 'components/SmallCard'
import { useHistory } from 'react-router-dom'

const cardItems = [
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card1.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5hrs ago',
    status: 'Buy',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card2.png'}`,
    briefContent: 'Learn Everything about managing and operating a Self Storage',
    time: '2days ago',
    status: 'Operate',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card3.png'}`,
    briefContent: 'Everything you need to know about selling a Self Storage',
    time: '3days ago',
    status: 'Sell',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card4.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5days ago',
    status: 'Buy',
    name: 'Raza'
  }
]

const Post = () => {
  const history = useHistory()

  return (
    <Box>
      <Container maxWidth="lg">
        <img style={{
          width: '100%',
          marginTop: '213px',
          marginBottom: '56px'
        }} src={process.env.PUBLIC_URL + 'images/post/postImg.png'} alt="Post" />
        <Box>
          <Typography sx={{
            color: '#2F2F2F',
            fontSize: '40px',
            fontWeight: '700 !important',
            marginBottom: '93px'
          }}>
            Learn How to Buy a Self Storage
          </Typography>
          <Stack direction="row" spacing={10} justifyContent="space-between" alignItems="center">
            <img src={process.env.PUBLIC_URL + 'images/post/postImg1.png'} alt="Post" />
            <Typography sx={{
              color: '#373737',
              fontSize: '18px',
              fontWeight: '400 !important',
              marginBottom: '20px'
            }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
              id diam amet proin scelerisque nunc. Enim odio pharetra metus,
              pulvinar phasellus etiam risus eu. Enim, malesuada pharetra placerat
              sodales. Enim elements nec tortor, massa metus. Id eget duis orci senectus
              senectus at ut tellus porttitor.
            </Typography>
          </Stack>
          <Typography sx={{
            color: '#212121',
            fontSize: '26px',
            fontWeight: '600 !important',
            marginBottom: '20px',
            mt: '85px !important'
          }}>
            Heading Text Here
          </Typography>
          <Typography sx={{
            color: '#373737',
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tortor sapien sagittis maecenas tincidunt.
            Quis pretium ornare id diam amet proin scelerisque nunc. Enim odio pharetra metus,
            pulvinar phasellus etiam risus eu.
            Enim, malesuada pharetra placerat sodales. Enim elementum hendrerit
            vitae convallis sed sed blandit aenean diam.
            Mattis neque leo et, donec. Hac quis neque turpis nec tortor, massa metus.
            Id eget duis orci senectus senectus at ut tellus porttitor.
            Non urna, sociis luctus dolor. Eu nec ullamcorper sollicitudin bibendum mi quisque mattis eu.
            At tempus sem interdum elit laoreet at tellus volutpat sed. Et id odio gravida urna, nunc feugiat ut.
            Eros non, velit ut a, leo fermentum non consequat. Purus urna massa lorem vitae.</Typography>
          <Typography sx={{
            color: '#373737',
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nunc tortor sapien sagittis maecenas tincidunt. Quis pretium ornare id diam amet proin scelerisque nunc.
            Enim odio pharetra metus, pulvinar phasellus etiam risus eu. Enim, malesuada pharetra placerat sodales.
            Enim elementum hendrerit vitae convallis</Typography>
          <Typography sx={{
            color: '#373737',
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tortor sapien sagittis maecenas tincidunt.
            Quis pretium ornare id diam amet proin scelerisque nunc. Enim odio pharetra metus,
            pulvinar phasellus etiam risus eu.
            Enim, malesuada pharetra placerat sodales. Enim elementum hendrerit vitae convallis
          </Typography>
          <Typography sx={{
            color: '#373737',
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing el
          </Typography>
          <Typography sx={{
            color: '#373737',
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tortor sapien sagittis maecenas tincidunt.
            Quis pretium ornare id diam amet proin scelerisque nunc. Enim odio pharetra metus,
            pulvinar phasellus etiam risus eu.
            Enim, malesuada pharetra placerat sodales. Enim elementum hendrerit vitae convallis
          </Typography>
        </Box>
        <Box sx={{ padding: '85px 0px', backgroundColor: '#F9F9F9', mt: '66px' }}>
          <Stack direction="row" spacing={10} justifyContent="space-between" alignItems="center">
            <img src={process.env.PUBLIC_URL + 'images/post/image3.png'} alt="Post" />
            <Box>
              <Typography sx={{
                color: '#212121',
                fontSize: '26px',
                fontWeight: '600 !important',
                marginBottom: '20px'
              }}>
                More information on how to buy a Self Storage
              </Typography>
              <Typography sx={{
                color: '#373737',
                fontSize: '18px',
                fontWeight: '400 !important',
                marginBottom: '20px'
              }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
                id diam amet proin scelerisque nunc. Enim odio pharetra metus,
                pulvinar phasellus etiam risus eu. Enim, malesuada pharetra placerat
                sodales. Enim elements nec tortor, massa metus. Id eget duis orci senectus
                senectus at ut tellus porttitor.
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={10} sx={{ mt: '104px' }} justifyContent="space-between" alignItems="center">
            <Box>
              <Typography sx={{
                color: '#212121',
                fontSize: '26px',
                fontWeight: '600 !important',
                marginBottom: '20px'
              }}>
                More information on how to buy a Self Storage
              </Typography>
              <Typography sx={{
                color: '#373737',
                fontSize: '18px',
                fontWeight: '400 !important',
                marginBottom: '20px'
              }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
                id diam amet proin scelerisque nunc. Enim odio pharetra metus,
                pulvinar phasellus etiam risus eu. Enim, malesuada pharetra placerat
                sodales. Enim elements nec tortor, massa metus. Id eget duis orci senectus
                senectus at ut tellus porttitor.
              </Typography>
            </Box>
            <img src={process.env.PUBLIC_URL + 'images/post/image5.png'} alt="Post" />
          </Stack>
        </Box>
      </Container>
      <Box sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/post/background.png)`,
        backgroundRepeat: 'round',
        paddingTop: '95px',
        paddingBottom: '85px',
        position: 'relative',
        textAlign: 'center'
      }}>
        <img style={{
          position: 'absolute',
          bottom: 0,
          left: 0
        }} src={process.env.PUBLIC_URL + '/images/post/ellipse1.png'} alt='' />
        <Typography sx={{
          color: '#FFFFFF',
          fontSize: '35px',
          fontWeight: '700 !important',
          marginBottom: '20px'
        }}>
          Do you have a Self Storage for Sale?
        </Typography>
        <Container maxWidth="md">
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400 !important',
            marginBottom: '20px', 
            color: 'white !important',
            mb: '50px !important'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nunc tortor sapien sagittis maecenas tincidunt.
            Quis pretium ornare id diam amet proin scelerisque nunc.
          </Typography>
        </Container>
        <MButton
          variant="contained"
          sx={{
            color: '#021850',
            backgroundColor: '#FFCE31',
            fontSize: '18px',
            fontWeight: '500 !important',
            width: 251,
            height: 60,
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: '#FFCE31 !important',
              color: '#021850 !important',
            },
            '&:active': {
              backgroundColor: '#FFCE31 !important',
              color: '#021850 !important',
            },
            '&:focus': {
              backgroundColor: '#FFCE31 !important',
              color: '#021850 !important',
            }
          }}
          onClick={() => history.push('sell-yourself-storage')}
        >Sell Your Self Storage</MButton>
      </Box>
      <Box sx={{
        backgroundColor: '#F9F9F9',
        pt: '100px', pb: '105px',
        pl: '100px', pr: '100px'
      }}>
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: '60px' }}>
            <Box>
              <Typography
                sx={{
                  color: '#2F2F2F',
                  fontSize: '35px',
                  fontWeight: '600 !important',
                  marginBottom: '20px'
                }}
              >Other Similar Post</Typography>
              <Typography sx={{
                color: '#373737',
                fontSize: '18px',
                fontWeight: '400 !important',
                marginBottom: '20px',
                maxWidth: '654px'
              }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nunc tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
              </Typography>
            </Box>
            <MButton
              disableRipple={true}
              sx={{
                color: '#373737',
                backgroundColor: 'transparent',
                fontSize: '18px',
                fontWeight: '500 !important',
                '&:hover': {
                  backgroundColor: 'transparent !important',
                  color: '#373737 !important',
                }
              }} endIcon={<ArrowForwardIosIcon />}
              onClick={() => history.push('active-listing')}>View More</MButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {
              cardItems.map((item, key) => (
                <SmallCard
                  cardImageURL={item.cardImageURL}
                  briefContent={item.briefContent}
                  time={item.time}
                  status={item.status}
                  name={item.name}
                  key={key}
                />
              ))
            }
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default Post

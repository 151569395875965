import * as React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined'
import { MButton } from 'components/CustomMaterial'
import { useHistory } from 'react-router-dom'

const StorageCard = ({ cardStyle, cardImageURL, }) => {
  const history = useHistory()

  return (
    <Card sx={{ maxHeight: '539px', maxWidth: "398.77px", borderRadius: '10px', pb: 3, ...cardStyle }}>
      <CardMedia
        component="img"
        image={cardImageURL}
        alt="storage image"
        height="265.77px"
        width="100%"
        sx={{ padding: '18px 22.79px 25px 22px', borderRadus: '40px !important' }}
      />
      <CardContent sx={{ mt: '0px', padding: '0px 6px' }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <LocationOnOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '25px',
                fontWeight: '600',
                lineHeight: '20px'
              }} primary="Jersy City, NJ" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <HomeWorkOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '20px'
              }} primary="Total Units: 89" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SupervisorAccountOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '20px'
              }} primary="Occupancy:   50%" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CropFreeOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '20px'
              }} primary="Size:   40,233 Sq Ft" />
          </ListItem>
        </List>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <MButton 
          onClick={() => history.push("detail")}
          sx={{
            minWidth: '277px',
            minHeight: '50px',
            color: 'white',
            fontSize: '16px',
            fontWeight: '600',
            backgroundColor: '#1849C6',
            borderRadius: '10px'
          }}
          variant="contained" 
          color="primary">
          View More Detail
        </MButton>
      </CardActions>
    </Card>
  )
}

export default StorageCard

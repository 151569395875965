import * as React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Stack,
  Icon
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { MButton } from 'components/CustomMaterial'
import { useHistory } from 'react-router-dom'

const SamllCard = ({ cardStyle, cardImageURL, briefContent, time, status, name }) => {
  const history = useHistory()

  return (
    <Card sx={{
      height: '433px', width: "287px",
      boxShadow: '0px 10px 40px rgba(1, 31, 109, 0.4)', borderRadius: '10px', ...cardStyle
    }}>
      <CardMedia
        component="img"
        image={cardImageURL}
        alt="storage image"
        height="202px"
        width="100%"
      // sx={{padding: '18px 22.79px 25px 22px', borderRadus: '40px !important'}}
      />
      <CardContent sx={{ mt: '21px', padding: '0px 11px' }}>
        <Typography sx={{
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '30px',
          color: '#2F2F2F',
          height: '79px',
          marginBottom: '21px'
        }}>
          {briefContent}
        </Typography>
        <Stack direction="row" justifyContent="space-around">
          <Typography sx={{
            color: '#000103B2',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            display: 'contents'
          }}>
            <AccessTimeIcon />
            {time}
          </Typography>
          <Typography sx={{
            color: '#000103B2',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            display: 'contents'
          }}>
            <Icon>
              <img sx={{
                verticalAlign: 'bottom !important',
                paddingBottom: '2px'
              }} src={process.env.PUBLIC_URL + "images/statusIcon.png"} alt="avatar" />
            </Icon>
            {status}
          </Typography>
          <Typography sx={{
            color: '#000103B2',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            display: 'contents'
          }}>
            <Icon>
              <img sx={{
                verticalAlign: 'bottom !important',
                paddingBottom: '2px'
              }} src={process.env.PUBLIC_URL + "images/avatar.png"} alt="avatar" />
            </Icon>
            {name}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', mt: 1 }}>
        <MButton
          onClick={() => history.push("post")}
          sx={{
            width: '205px',
            height: '40px',
            color: 'white',
            fontSize: '16px',
            fontWeight: '600',
            backgroundColor: '#1849C6',
            borderRadius: '10px'
          }}
          variant="contained">
          Read More
        </MButton>
      </CardActions>
    </Card>
  )
}

export default SamllCard

import * as React from 'react'
import {
  Card,
  CardMedia,
  Typography,
  Stack,
  Box
} from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined'
import { MButton } from 'components/CustomMaterial'
import { useHistory } from 'react-router-dom'

const SamllCard = ({ cardStyle, cardImageURL }) => {
  const history = useHistory()

  return (
    <Card sx={{
      width: '100%',
      height: '416px',
      margin: '20px 0px',
      padding: '32px 40px',
      borderRadius: '20px',
      backgroundColor: '#F9F9F9',
      boxShadow: 'none',
      display: 'flex', ...cardStyle
    }} >
      <CardMedia
        component="img"
        image={cardImageURL}
        alt="storage image"
        sx={{ width: 'auto', mr: '34px' }}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginBottom: '40px',
          textAlign: 'right',
        }}>
          <LocationOnOutlinedIcon />
          Jersy City, NJ
        </Typography>
        <Typography sx={{
          color: '#2F2F2F',
          fontSize: '30px',
          fontWeight: '600 !important',
          marginBottom: '40px'
        }}>
          Self Storage Heading
        </Typography>
        <Stack sx={{ mb: '29px' }} direction="row" justifyContent="space-between">
          <Box>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '600 !important',
              marginBottom: '16.22px'
            }}>
              <HomeWorkOutlinedIcon />
              Total Units
            </Typography>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '400 !important',
              marginBottom: '40px'
            }}>89 units</Typography>
          </Box>
          <Box>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '600 !important',
              marginBottom: '16.22px'
            }}>
              <SupervisorAccountOutlinedIcon />
              Size
            </Typography>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '400 !important',
              marginBottom: '40px'
            }}>40,233 Sq Ft</Typography>
          </Box>
          <Box>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '600 !important',
              marginBottom: '16.22px'
            }}>
              <CropFreeOutlinedIcon />
              Occupancy
            </Typography>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '400 !important',
              marginBottom: '40px'
            }}>50%</Typography>
          </Box>
        </Stack>
        <MButton
          variant="contained"
          sx={{
            alignSelf: 'end',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: '600 !important',
            height: '55px',
            width: '200px',
            borderRadius: '10px',
            backgroundColor: '#1849C6',
            '&:hover': {
              backgroundColor: '#1849C6 !important',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#1849C6 !important',
              color: '#FFFFFF !important',
            },
            '&:focus': {
              backgroundColor: '#1849C6 !important',
              color: '#FFFFFF !important',
            }
          }}
          onClick={() => history.push("detail")}
        >View More Details</MButton>
      </Box>
    </Card>
  )
}

export default SamllCard

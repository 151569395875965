import React from 'react'
import { BrowserRouter as Router, Redirect, /*Redirect,*/ Route, Switch } from 'react-router-dom'
// import { Container } from 'reactstrap'
import { Layout } from 'containers/Layout' 
import Home from 'pages/Home'
import ActiveListing from 'pages/ActiveListing'
import Contact from 'pages/Contact'
import SellYourSelfStorage from 'pages/SellYourSelfStorage'
import FreeEvaluation from 'pages/FreeEvaluation'
import Inquiring from 'pages/Inquiring'
import Detail from 'pages/Detail'
import Learn from 'pages/Learn'
import Post from 'pages/Post'
import ScrollToTop from 'react-router-scroll-top'
import 'styles/core.scss'

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home"/>
              </Route>
              <Route exact path="/home" component={Home} />
              <Route exact path="/active-listing" component={ActiveListing} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/sell-yourself-storage" component={SellYourSelfStorage} />
              <Route exact path="/free-evaluation" component={FreeEvaluation} />
              <Route exact path="/inquiring" component={Inquiring} />
              <Route exact path="/detail" component={Detail} />
              <Route exact path="/learn" component={Learn} />
              <Route exact path="/post" component={Post} />
            </Switch>
          </Layout>
        </ScrollToTop>
      </Router>
    )
  }
}

export default Routes

import * as React from 'react'
import {
  Card,
  Modal,
  Typography,
  Box,
  IconButton,
  InputAdornment
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import MailIcon from '@mui/icons-material/Mail'
import { ReactComponent as ShapeIcon } from "../../assets/icons/shape.svg"
import { MButton, MInput } from 'components/CustomMaterial'

const SearchNotifyModal = ({ open, setOpen, setStatus }) => {
  const notify = () => {
    setOpen(false)
    setStatus()
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
    >
      <Card sx={{
        position: 'absolute',
        top: '274px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 605,
        bgcolor: '#FFFFFF',
        borderRadius: '20px',
        textAlign: 'center',
      }}>
        <Box sx={{ textAlign: 'end !important', margin: '28px 28px 0px 0px', }}>
          <IconButton sx={{
            color: '#FFFFFF',
            backgroundColor: '#DBDBDC',
          }} onClick={() => setOpen(false)}>
            <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </Box>
        <Box sx={{ padding: '0px 86px' }}>
          <NotificationsIcon sx={{
            color: '#1849C6',
            fontSize: '50px',
            marginBottom: '20px',
          }} />
          <Typography sx={{
            color: '#000103B2',
            fontSize: '25px !important',
            fontWeight: '400 !important',
            lineHeight: '40px',
            marginBottom: '40px'
          }}>
            We will alert you anytime we have this specific Self Storage
          </Typography>
          <MInput
            sx={{
              backgroundColor: '#F6F8FD',
              width: '100%',
              height: '65px',
              borderRadius: '10px',
              marginBottom: '25px'
            }}
            placeholder="Your email address"
            startAdornment={
              <InputAdornment position="start">
                <MailIcon sx={{ color: '#A1A6B1' }} />
              </InputAdornment>}
          />
          <MButton
            variant="contained"
            sx={{
              color: '#000103B2',
              fontSize: '18px !important',
              fontWeight: '600 !important',
              borderRadius: '10px',
              backgroundColor: '#FFCE31',
              width: '100%',
              height: '64px',
              marginBottom: '45px',
              '&:hover': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2',
              },
              '&:active': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2 !important',
              },
              '&:focus': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2 !important',
              },
            }}
            endIcon={<ShapeIcon />}
            onClick={notify}
          >Notify Me</MButton>
        </Box>
      </Card>
    </Modal>
  )
}

export default SearchNotifyModal

import React from 'react'
import useStyles from './styles.js'
import { useHistory } from 'react-router-dom'

const Logo = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <img className={classes.root}
      src={process.env.PUBLIC_URL + "logo.png"} alt="log" onClick={() => history.push('/home')} />
  )
}

export default Logo

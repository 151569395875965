import React from 'react'
import {
  Box,
  Stack,
  Container,
} from '@mui/material'
import About from './About'
import QuickLinks from './QuickLinks'
import NewsLetter from './NewsLetter'
import GetSocial from './GetSocial'
import useStyles from './styles.js'

const Footer = () => {
  const classes = useStyles()

  return (
    <Box sx={{ pb: "51px", position: "relative", overflow: "hidden" }}>
      <Box className={classes.rectangle1}></Box>
      <Box className={classes.rectangle2}></Box>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-around">
          <About />
          <QuickLinks />
          <NewsLetter />
          <GetSocial />
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer

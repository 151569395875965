import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  root: {

  },
  toolbar: {
    minHeight: 'unset !important',
    height: '92px !important',
    justifyContent: 'space-between'
  },
}))

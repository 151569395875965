import React from 'react'
import { MButton } from 'components/CustomMaterial'
import {
  Box,
  Typography,
  FormControl,
  InputBase
} from '@mui/material'
import { styled } from '@mui/material/styles'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: "12px",
    padding: '15px 26px',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

const NewsLetter = () => {
  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography sx={{ mt: 4, mb: "22px", fontSize: "18px" }} variant="h6" component="div">
        NEWLETTER
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Get latest updates right in your inbox
      </Typography>
      <FormControl>
        <Box>
          <BootstrapInput sx={{ width: '250px' }} placeholder="enter your email" />
        </Box>
        <Box>
          <MButton
            variant="contained"
            sx={{
              backgroundColor: '#1849C6',
              width: "161px", mt: "10px", borderRadius: "10px",
              height: "50px", fontWeight: "600", fontSize: 16, padding: "10px 20px"
            }}
          >Subscribe Now</MButton>
        </Box>
      </FormControl>

    </Box>
  )
}

export default NewsLetter

import React from 'react'
import {
  Box,
  Container,
  Typography,
  InputAdornment,
  Tabs,
  Tab,
  Stack,
  SvgIcon,
  Pagination,
  Grid
} from '@mui/material'
import { MInput } from 'components/CustomMaterial'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as SortIcon } from "../../assets/icons/sort-by-icon.svg"
import { SmallCard } from 'components/SmallCard'

const cardItems = [
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card1.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5hrs ago',
    status: 'Buy',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card2.png'}`,
    briefContent: 'Learn Everything about managing and operating a Self Storage',
    time: '2days ago',
    status: 'Operate',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card3.png'}`,
    briefContent: 'Everything you need to know about selling a Self Storage',
    time: '3days ago',
    status: 'Sell',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card4.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5days ago',
    status: 'Buy',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card1.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5hrs ago',
    status: 'Buy',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card2.png'}`,
    briefContent: 'Learn Everything about managing and operating a Self Storage',
    time: '2days ago',
    status: 'Operate',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card3.png'}`,
    briefContent: 'Everything you need to know about selling a Self Storage',
    time: '3days ago',
    status: 'Sell',
    name: 'Raza'
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/small-card4.png'}`,
    briefContent: 'Everything you need to know about how to buy a Self Storage',
    time: '5days ago',
    status: 'Buy',
    name: 'Raza'
  }
]

const Learn = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box>
      <img
        src={process.env.PUBLIC_URL + 'images/active-listing/top-background.png'}
        style={{
          position: 'absolute',
          top: '134px',
        }}
        alt="backgroundImage"
      />
      <Box sx={{ backgroundColor: '#F6F8FD', }}>
        <Typography sx={{
          fontSize: '40px',
          fontWeight: '600 !important',
          color: '#2F2F2F',
          paddingTop: '160px',
          textAlign: 'center',
        }}>
          Learn About Self Storgae
        </Typography>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginTop: '15px',
          textAlign: 'center',
          paddingBottom: '36px'
        }}>
          Learn everything about a self storage
        </Typography>
      </Box>

      <Container maxWidth="xl" sx={{ width: '100%', bgcolor: 'background.paper', mt: '100px' }}>
        <Tabs value={value} onChange={handleChange}
          sx={{
            borderBottom: 1, borderColor: 'divider',
            '& .css-heg063-MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            }
          }}>
          <Tab label="All Categories" />
          <Tab label="Buying a Self Storage" />
          <Tab label="Selling a Self Storage" />
          <Tab label="Operating a Self Storage" />
        </Tabs>
        <Box>
          <Stack direction="row" alignItems="center" justifyContent="right" sx={{ margin: '30px 0' }}>
            <Typography>
              <SvgIcon component={SortIcon} inheritViewBox />
              Sort by:
            </Typography>
            <MInput
              sx={{
                width: '182px !important',
                height: '55px !important',
                marginLeft: '15px'
              }}
              endAdornment={
                <InputAdornment position="end">
                  <KeyboardArrowDownIcon />
                </InputAdornment>}
              defaultValue="Newest"
            />
          </Stack>
          <Grid container space={2}>
            {
              cardItems.map((item, key) => (
                <Grid item xs={3} sx={{ mb: '20px' }}>
                  <SmallCard
                    cardImageURL={item.cardImageURL}
                    briefContent={item.briefContent}
                    time={item.time}
                    status={item.status}
                    name={item.name}
                    key={key}
                    cardStyle={{
                      margin: 'auto',
                      backgroundColor: '#FFFFFF !important',
                      boxShadow: 'none',
                      border: '3px solid #EEEEEE'
                    }}
                  />
                </Grid>
              ))
            }
          </Grid>
          <Stack direction="row" justifyContent="right" alignItems="center">
            <Pagination size="large"
              sx={{
                '& .Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  color: '#1849C6',
                  borderColor: '#1849C6'
                },
                marginTop: '57.5px',
                marginBottom: '40px'
              }}
              count={4} variant="outlined" color="primary" />
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}

export default Learn

import React from 'react'
import Carousel from 'react-material-ui-carousel'
import {
  Box,
  Typography,
  Card,
  Container,
  Stack,
  Divider,
  TextareaAutosize,
  InputAdornment
} from '@mui/material'
import { MButton, MInput } from 'components/CustomMaterial'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg"
import { ReactComponent as ShapeIcon } from "../../assets/icons/shape1.svg"
import { StorageCard } from 'components/StorageCard'
import { useHistory } from 'react-router-dom'


const arrayImage = [
  { url: `${process.env.PUBLIC_URL + 'images/cards/storage-image1.jpg'}` },
  { url: `${process.env.PUBLIC_URL + 'images/cards/storage-image2.jpg'}` },
  { url: `${process.env.PUBLIC_URL + 'images/cards/storage-image3.jpg'}` }
]
const Detail = () => {
  const history = useHistory()
  const anArrayOfNumbers =
    [
      <img style={{
        width: '129px',
        height: '97px'
      }} src={process.env.PUBLIC_URL + 'images/cards/storage-image1.jpg'} alt="" />,
      <img style={{
        width: '129px',
        height: '97px'
      }} src={process.env.PUBLIC_URL + 'images/cards/storage-image2.jpg'} alt="" />,
      <img style={{
        width: '129px',
        height: '97px'
      }} src={process.env.PUBLIC_URL + 'images/cards/storage-image3.jpg'} alt="" />
    ]
  return (
    <Box sx={{ mt: '181px' }}>
      <Container maxWidth="lg">
        <Typography sx={{
          color: '#2F2F2F',
          fontSize: '35px',
          fontWeight: '600 !important',
          marginBottom: 30
        }}>
          The Name of This Self Storage
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography sx={{
              color: '#000103B2',
              fontSize: '20px',
              fontWeight: '400 !important',
              marginBottom: 30
            }}>
              <FmdGoodIcon />
              1901 Thornridge Cir. Shiloh, Hawaii 81063
            </Typography>
            <Carousel
              IndicatorIcon={anArrayOfNumbers}
              indicatorIconButtonProps={{
                style: {
                  padding: '10px',
                  position: 'relative',

                }
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  padding: '10px',
                  opacity: 0.5
                }
              }}
              indicatorContainerProps={{
                style: {
                  textAlign: 'left',
                }
              }}
              sx={{ width: '849px' }}
              animation="slide"
              autoPlay={false}
            >
              {
                arrayImage.map((item, i) => (
                  <img style={{
                    position: 'relative',
                    width: '849px',
                    height: '642px'
                  }} key={i} src={item.url} alt="" />
                ))
              }
            </Carousel>
          </Box>
          <Box sx={{ ml: '100px' }}>
            <MButton
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#1849C6',
                borderRadius: '10px',
                height: '60px',
                width: '350px',
                color: '#FFFFFF',
                fontSize: '18px',
                fontWeight: '600 !important',
                marginBottom: '15px'
              }}
              href="#request-detail"
            >
              Request price
            </MButton>
            <Card
              sx={{ borderRadius: '10px', backgroundColor: "white", boxShadow: "none", border: '1px solid #EEEEEE' }}>
              <Stack direction="row" justifyContent="space-around"
                sx={{ margin: '35px 25px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '30px',
                  fontWeight: '600 !important',
                }}>
                  Total Units
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '35px',
                  fontWeight: '400 !important',
                }}>
                  89
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ margin: '35px 0px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <SupervisorAccountOutlinedIcon />
                  Size:  7 X 6Ft
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  25 Units
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ mb: '43px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <CropFreeOutlinedIcon />
                  Occupancy
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  50%
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ margin: '35px 0px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <SupervisorAccountOutlinedIcon />
                  Size:  7 X 6Ft
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  25 Units
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ mb: '43px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <CropFreeOutlinedIcon />
                  Occupancy
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  50%
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ margin: '35px 0px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <SupervisorAccountOutlinedIcon />
                  Size:  7 X 6Ft
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  25 Units
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ mb: '43px' }}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                }}>
                  <CropFreeOutlinedIcon />
                  Occupancy
                </Typography>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '20px',
                  fontWeight: '400 !important',
                }}>
                  50%
                </Typography>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </Container>
      <Container sx={{ marginTop: '74px' }}>
        <Typography sx={{
          color: '#2F2F2F',
          fontSize: '35px',
          fontWeight: '600 !important',
          marginBottom: '30px'
        }}>
          Description
        </Typography>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginBottom: '30px',
          mb: '68px !important'
        }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tortor sapien sagittis maecenas tincidunt.
          Quis pretium ornare id diam amet proin scelerisque nunc. Enim odio pharetra metus,
          pulvinar phasellus etiam risus eu. Enim, malesuada pharetra placerat sodales. Enim elements nec tortor,
          massa metus. Id eget duis orci senectus senectus at ut tellus porttitor. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Nunc tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
          id diam amet proin scelerisque nunc. Enim odio pharetra metus, pulvinar phasellus etiam ris.
          Id eget duis orci senectus senectus at ut tellus porttitor.
        </Typography>
      </Container>
      <Box id="request-detail" sx={{
        backgroundRepeat: 'round',
        backgroundColor: '#F6F8FD',
        pt: '110px',
        pb: '154px',
        position: 'relative'
      }}>
        <img style={{
          position: 'absolute',
          bottom: 0
        }} src={process.env.PUBLIC_URL + '/images/details/background1.png'} alt="" />
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
          <Typography sx={{
            color: '#2F2F2F',
            fontSize: '35px',
            fontWeight: '600 !important',
            marginBottom: '30px'
          }}>Request Details</Typography>
          <Typography sx={{
            color: '#000103B2',
            fontSize: '20px',
            fontWeight: '400 !important',
            marginBottom: '30px'
          }}>
            Provide us some details about the size and number of units you need
          </Typography>
          <Card sx={{
            backgroundColor: '#FFFFFFE5 !important',
            border: '1px solid #CDD4E7',
            borderRadius: '30px',
            padding: '53px 70px',
            marginTop: '50px',
            marginBottom: '50px',
            boxShadow: 'none'
          }}>
            <MInput
              sx={{
                backgroundColor: '#F6F8FD !important',
                borderRadius: '10px',
                border: 'none',
                marginBottom: '20px',
                width: '100%',
                color: '#A1A6B1',
              }}
              placeholder="John"
              startAdornment={
                <InputAdornment position="start">
                  <UserIcon color="disabled" />
                </InputAdornment>}
            />
            <MInput
              sx={{
                backgroundColor: '#F6F8FD !important',
                borderRadius: '10px',
                border: 'none',
                marginBottom: '20px',
                width: '100%',
                color: '#A1A6B1',
              }}
              placeholder="Number"
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>}
            />
            <MInput
              sx={{
                backgroundColor: '#F6F8FD !important',
                borderRadius: '10px',
                border: 'none',
                marginBottom: '20px',
                width: '100%',
                color: '#A1A6B1',
              }}
              placeholder="Mail Address"
              startAdornment={
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>}
            />
            <TextareaAutosize
              minRows={5}
              placeholder="Your message..."
              style={{
                backgroundColor: '#F6F8FD',
                borderRadius: '10px',
                border: 'none',
                marginBottom: '20px',
                width: '100%',
                color: '#A1A6B1',
              }}
            />

            <MButton
              variant="contained"
              sx={{
                fontSize: '18px',
                color: 'white',
                fontWeight: '600 !important',
                backgroundColor: '#1849C6',
                width: '100%',
                height: '64px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#1849C6 !important',
                  color: 'white',
                },
                '&:active': {
                  backgroundColor: '#1849C6 !important',
                  color: '#FFFFFF !important',
                },
                '&:focus': {
                  backgroundColor: '#1849C6 !important',
                  color: '#FFFFFF !important',
                }
              }}
              endIcon={<ShapeIcon />}
              onClick={() => history.push('inquiring')}
            >
              Request Detail
            </MButton>
          </Card>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: '#1849C6',
        pt: '133px',
        pb: '110px'
      }}>
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mr: 6, ml: 6, mb: '70px' }}>
            <Typography sx={{
              fontSize: '35px',
              fontWeight: '600 !important',
              marginBottom: 30,
              color: 'white !important',
              mb: '0 !important'
            }}>
              Other Similar Self Storages For Sale
            </Typography>
            <MButton
              variant="contained"
              sx={{
                fontSize: '18px',
                color: '#000103B2',
                fontWeight: '500 !important',
                backgroundColor: '#FFCE31',
                width: '162px',
                height: '50px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2',
                },
                '&:active': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#F000103B2FFFFF !important',
                },
                '&:focus': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                }
              }}
              endIcon={<ArrowForwardIosIcon />}
            >
              View More
            </MButton>
          </Stack>
          <Stack direction="row" justifyContent="space-around">
            <StorageCard
              cardStyle={{
                width: '399px', height: '539px',
                backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
              }}
              cardImageURL={process.env.PUBLIC_URL + 'images/cards/image1.jpg'}
            />
            <StorageCard
              cardStyle={{
                width: '399px', height: '539px',
                backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
              }}
              cardImageURL={process.env.PUBLIC_URL + 'images/cards/image2.png'}
            />
            <StorageCard
              cardStyle={{
                width: '399px', height: '539px',
                backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
              }}
              cardImageURL={process.env.PUBLIC_URL + 'images/cards/image3.png'}
            />
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default Detail

import React from 'react'
import {
  Box,
  Card,
  Container,
  FormControl,
  Typography,
  InputAdornment,
  Stack
} from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { MButton, MInput } from 'components/CustomMaterial'
import { StorageCard } from 'components/StorageCard'

const ActiveListing = () => {

  return (
    <Box sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/section3bg.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right'
    }}>
      <Container>
        <Box sx={{ mt: '50px', mb: '60px' }}>
          <Typography variant="h4" component="div" sx={{
            fontSize: '35px',
            fontWeight: '600',
            color: '#2F2F2F',
            textAlign: 'center',
            marginBottom: '20px',
          }}>
            Active Listing Self Storages
          </Typography>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400',
            textAlign: 'center',
            lineHeight: '30px',
            color: "rgba(0, 1, 3, 0.7)",
            padding: "0px 200px"
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nunc tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
          </Typography>
        </Box>
      </Container>
      <Box sx={{ margin: "0px 100px" }}>
        <Card sx={{
          padding: "30px 36px 34px 34px",
          boxShadow: "0px 5px 50px rgba(1, 31, 109, 0.1)",
          borderRadus: "20px"
        }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormControl>
              <MInput
                label="Location"
                sx={{ marginTop: '15px', borderRadius: "20px", width: '418px !important', height: '60px !important' }}
                placeholder="Search location"
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>}
              />
            </FormControl>
            <Stack direction="row" spacing="20px">
              <FormControl>
                <MInput
                  label="Properties"
                  sx={{ marginTop: '15px', borderRadius: "20px", width: '200px !important', height: '60px !important' }}
                  placeholder="Unit Count"
                  startAdornment={
                    <InputAdornment position="start">
                      <HomeWorkOutlinedIcon />
                    </InputAdornment>}
                />
              </FormControl>
              <FormControl>
                <MInput
                  sx={{ borderRadius: "20px", marginTop: "47px", width: '200px !important', height: '60px !important' }}
                  placeholder="Occupancy"
                  startAdornment={
                    <InputAdornment position="start">
                      <SupervisorAccountOutlinedIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>

            <MButton
              sx={{borderRadius: '10px',
              marginTop: '47px !important',
              width: '148px',
              height: '60px',
              fontSize: '20px',
              fontWeight: '400',
              color: '#000103B2',
              backgroundColor: '#FFCE31',
              '&:hover': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2',
              },
              '&:active': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2 !important',
              },
              '&:focus': {
                backgroundColor: '#FFCE31 !important',
                color: '#000103B2 !important',
              }}}
              startIcon={<SearchIcon />}>
              Search</MButton>
          </Stack>
        </Card>
      </Box>

      <Box sx={{ mr: '138px', ml: '62px', mt: '60px', mb: '46px' }}>
        <Stack direction="row" justifyContent="space-between">
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image1.jpg'}
          />
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image2.png'}
          />
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image3.png'}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: '20px' }}>
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image4.png'}
          />
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image5.png'}
          />
          <StorageCard
            cardStyle={{
              width: '399px', height: '539px',
              backgroundColor: '#F9F9F9', boxShadow: '0px 20px 30px rgba(1, 31, 109, 0.4)'
            }}
            cardImageURL={process.env.PUBLIC_URL + 'images/cards/image6.png'}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default ActiveListing

import React from 'react'
import {
  Box,
  Container,
  Typography,
  Card,
  InputAdornment,
  TextareaAutosize
} from '@mui/material'
import { MButton, MInput } from 'components/CustomMaterial'
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg"
import { ReactComponent as ShapeIcon } from "../../assets/icons/shape1.svg"
import { useHistory } from 'react-router-dom'

const Contact = () => {
  const history = useHistory()

  return (
    <Box>
      <img
        src={process.env.PUBLIC_URL + 'images/active-listing/top-background.png'}
        style={{
          position: 'absolute',
          top: '134px',
        }}
        alt="backgroundImage"
      />
      <Box sx={{ backgroundColor: '#F6F8FD', }}>
        <Typography sx={{
          fontSize: '40px',
          fontWeight: '600 !important',
          color: '#2F2F2F',
          paddingTop: '160px',
          textAlign: 'center',
        }}>
          Contact us
        </Typography>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginTop: '15px',
          textAlign: 'center',
          paddingBottom: '36px'
        }}>
          Let us know what we can help you with
        </Typography>
      </Box>
      <Container maxWidth="sm" >
        <Card sx={{
          backgroundColor: '#FFFFFFE5 !important',
          border: '1px solid #CDD4E7',
          borderRadius: '30px',
          padding: '53px 70px',
          marginTop: '50px',
          marginBottom: '50px'
        }}>
          <MInput
            sx={{
              backgroundColor: '#F6F8FD !important',
              borderRadius: '10px',
              border: 'none',
              marginBottom: '20px',
              width: '100%',
              color: '#A1A6B1',
            }}
            placeholder="John"
            startAdornment={
              <InputAdornment position="start">
                <UserIcon color="disabled" />
              </InputAdornment>}
          />
          <MInput
            sx={{
              backgroundColor: '#F6F8FD !important',
              borderRadius: '10px',
              border: 'none',
              marginBottom: '20px',
              width: '100%',
              color: '#A1A6B1',
            }}
            placeholder="Number"
            startAdornment={
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>}
          />
          <MInput
            sx={{
              backgroundColor: '#F6F8FD !important',
              borderRadius: '10px',
              border: 'none',
              marginBottom: '20px',
              width: '100%',
              color: '#A1A6B1',
            }}
            placeholder="Mail Address"
            startAdornment={
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>}
          />
          <TextareaAutosize
            minRows={5}
            placeholder="Your message..."
            style={{
              backgroundColor: '#F6F8FD',
              borderRadius: '10px',
              border: 'none',
              marginBottom: '20px',
              width: '100%',
              color: '#A1A6B1',
            }}
          />

          <MButton
            variant="contained"
            sx={{
              fontSize: '18px',
              color: 'white',
              fontWeight: '600 !important',
              backgroundColor: '#1849C6',
              width: '100%',
              height: '64px',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#1849C6 !important',
                color: 'white',
              },
              '&:active': {
                backgroundColor: '#1849C6 !important',
                color: '#FFFFFF !important',
              },
              '&:focus': {
                backgroundColor: '#1849C6 !important',
                color: '#FFFFFF !important',
              }
            }}
            endIcon={<ShapeIcon />}
            onClick={() => history.push('inquiring')}
          >
            Send Message
          </MButton>
        </Card>
      </Container>
    </Box>
  )
}

export default Contact

import React from 'react'
import {
  Box,
  Link,
  Typography,
} from '@mui/material'

const quickLinks = [
  { text: 'Active Listening', to: '/active-listing' },
  { text: 'Sell your Self Storage', to: '/sell-yourself-storage' },
  { text: 'Free Evaluation', to: '/free-evaluation' },
  { text: 'Learn', to: '/learn' },
]

const QuickLinks = () => {

  return (
    <Box>
      <Typography sx={{ mt: 4, mb: 3, fontSize: "18px" }} variant="h6" component="div">
        QUICK LINK
      </Typography>
      {
        quickLinks.map((item, key) => {
          return (
            <Box sx={{ mb: 2 }}>
              <Link href={item.to} underline="none" color="inherit" variant="body2" sx={{ fontSize: "16px" }}>
                {item.text}
              </Link>
            </Box>
          )
        })
      }
    </Box>
  )
}

export default QuickLinks

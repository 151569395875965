import React from 'react'
import {
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { StorageCard } from 'components/StorageCard'
import Carousel from 'react-material-ui-carousel'
import CircleIcon from '@mui/icons-material/Circle'

const FeaturedSelfStorage = () => {
  const items = [0, 1, 3, 4]

  return (
    <Box sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/ellipse6.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0px -135px'
    }}>
      <Container>
        <Box sx={{pt: '156px', mb: '60px' }}>
          <Typography variant="h4" component="div" sx={{
            fontSize: '35px',
            fontWeight: '600 !important',
            color: '#021850',
            textAlign: 'center',
            marginBottom: '20px',
          }}>
            Featured Self Storage
          </Typography>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: '400 !important',
            textAlign: 'center',
            lineHeight: '30px',
            color: "rgba(0, 1, 3, 0.7)",
            padding: "0px 200px"
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nunc tortor sapien sagittis maecenas tincidunt. Quis pretium ornare
          </Typography>
        </Box>

        <Box>
          <Carousel
            IndicatorIcon={<CircleIcon />}
            indicatorIconButtonProps={{
              style: {
                margin: '45px 7px 0px 7px',
                border: '1px solid #DBDEE7',
                color: '#FFFFFF'
              }
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: '#1849C6',
                border: '1px solid #1849C6',
                backgroundColor: '#1849C6',
              }
            }}
            animation="slide"
            autoPlay={false}
          >
            {
              items.map((item, i) => (
                <Stack key={i} direction="row" justifyContent="space-between">
                  <StorageCard
                    cardStyle={{ backgroundColor: '#F6F8FD', boxShadow: 'none' }}
                    cardImageURL={process.env.PUBLIC_URL + 'images/cards/storage-image1.jpg'}
                  />
                  <StorageCard
                    cardStyle={{ backgroundColor: '#F6F8FD', boxShadow: 'none' }}
                    cardImageURL={process.env.PUBLIC_URL + 'images/cards/storage-image2.jpg'}
                  />
                  <StorageCard
                    cardStyle={{ backgroundColor: '#F6F8FD', boxShadow: 'none' }}
                    cardImageURL={process.env.PUBLIC_URL + 'images/cards/storage-image3.jpg'}
                  />
                </Stack>
              ))
            }
          </Carousel>
        </Box>
      </Container>
    </Box>
  )
}

export default FeaturedSelfStorage

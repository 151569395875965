import React from 'react'

import HeroSection from './HeroSection'
import FeaturedSelfStorage from './FeaturedSelfStorage'
import ActiveListing from './ActiveListing'
import ViewMoreListing from './ViewMoreListing'
import LearnEverything from './LearnEverything'
import Contact from './Contact'

const Home = () => {

  return (
    <>
      <HeroSection />
      <FeaturedSelfStorage />
      <ActiveListing />
      <ViewMoreListing />
      <LearnEverything />
      <Contact />
    </>
  )
}

export default Home

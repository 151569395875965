import React from 'react'
import {
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { MButton } from 'components/CustomMaterial'
import { useHistory } from 'react-router-dom'

const Inquiring = () => {
  const history = useHistory()

  return (
    <Box sx={{
      paddingTop: '151px',
      paddingBottom: '111px',
      textAlign: 'center',
    }}>
      <Container maxWidth="md">
        <img src={process.env.PUBLIC_URL + 'images/evaluation/check.png'} alt="chack" />
        <Typography sx={{
          color: '#212121',
          fontSize: '30px',
          fontWeight: '600 !important',
          marginTop: '40px'
        }}>
          Your Inquiry Has Been Sent
        </Typography>
        <Typography sx={{
          color: '#373737',
          fontWeight: '400 !important',
          fontSize: '18px',
          marginTop: '18px'
        }}>
          We’ve recieved your inquiry and we will get in touch with you as soon as possible
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing="20px" sx={{ mt: '50px' }}>
          <MButton variant="contained"
            onClick={() => history.push('active-listing')}
            sx={{
              color: '#FFFFFF',
              backgroundColor: '#1849C6',
              fontSize: '18px',
              fontWeight: '600 !important',
              width: 191,
              height: 50,
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#1849C6 !important',
                color: '#FFFFFF',
              },
              '&:active': {
                backgroundColor: '#1849C6 !important',
                color: '#FFFFFF !important',
              },
              '&:focus': {
                backgroundColor: '#1849C6 !important',
                color: '#FFFFFF !important',
              }
            }}>Active Listing</MButton>
          <MButton variant="outlined"
            onClick={() => history.push('home')}
            sx={{
              color: '#021850',
              backgroundColor: '#FFFFFF',
              fontSize: '18px',
              fontWeight: '600 !important',
              width: '144px',
              height: '50px',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#FFFFFF !important',
                color: '#021850',
              },
              '&:active': {
                backgroundColor: '#FFFFFF !important',
                color: '#021850 !important',
              },
              '&:focus': {
                backgroundColor: '#FFFFFF !important',
                color: '#021850 !important',
              }
            }}>Go Home</MButton>
        </Stack>
      </Container>
    </Box>
  )
}

export default Inquiring

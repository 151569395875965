import React from 'react'
import {
  Card,
  Box,
  Typography,
  Container,
  Stack
} from '@mui/material'
import { MButton } from 'components/CustomMaterial'
import SendIcon from '@mui/icons-material/Send'
import { useHistory } from 'react-router-dom'

const Contact = () => {
  const history = useHistory()

  return (
    <Box sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/contactBg.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundColor: '#E8EFFF',
      padding: '145px 0',
      position: 'relative',
      bottom: '190px'
    }}>
      <Container>
        <img style={{
          position: 'absolute',
          bottom: '15.5%',
          left: '10.5%'
        }} src={process.env.PUBLIC_URL + "images/red-ellipse.png"} alt="ellipse" />
        <img style={{
          position: 'absolute',
          top: '17%',
          right: '20%'
        }} src={process.env.PUBLIC_URL + "images/mail-ellipse.png"} alt="ellipse" />
        <Card sx={{
          position: 'relative', backgroundColor: '#FFFFFFE5',
          borderRadius: '10px', boxShadow: '0px 30px 40px rgba(24, 73, 198, 0.1)', padding: '108px 90px'
        }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography sx={{
                color: '#021850',
                fontSize: '35px',
                fontWeight: '700',
              }}>
                Any Queries? Reach Out to Us
              </Typography>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: '400 !important',
                color: '#000103B2',
                marginBottom: '20px'
              }}>
                Feel free to write to us, we are happy to help you
              </Typography>
            </Box>
            <MButton
              variant="contained"
              sx={{
                borderRadius: '10px',
                backgroundColor: '#1849C6',
                width: '195px',
                height: '60px',
                color: 'white',
                fontSize: '18px',
                fontWeight: '400 !important',
              }}
              color='primary'
              endIcon={<SendIcon />}
              onClick={() => history.push('contact')}
            >Write to Us</MButton>
          </Stack>
        </Card>
      </Container>
    </Box>
  )
}

export default Contact

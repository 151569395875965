import React from 'react'
import {
  Box,
  Container,
  Typography,
  Card,
  Stack,
  FormControl,
  InputAdornment,
  Pagination,
  SvgIcon,
  Grid
} from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import SearchIcon from '@mui/icons-material/Search'
import DehazeIcon from '@mui/icons-material/Dehaze'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import NotificationsIcon from '@mui/icons-material/Notifications'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as SortIcon } from "../../assets/icons/sort-by-icon.svg"
import { MButton, MInput } from 'components/CustomMaterial'
import { LargeCard } from 'components/LargeCard'
import { StorageCard } from 'components/StorageCard'
import { SearchNotifyModal } from 'components/SearchNotifyModal'
import { useState } from 'react'

const card1Items = [
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/largecard/card1.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/largecard/card2.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/largecard/card3.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/largecard/card4.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/largecard/card5.png'}`,
  }
]

const card2Items = [
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image1.jpg'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image2.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image3.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image4.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image5.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image6.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image7.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image7.png'}`,
  },
  {
    cardImageURL: `${process.env.PUBLIC_URL + 'images/cards/image6.png'}`,
  }
]

const ActiveListing = () => {
  const [view, setView] = useState(0)
  const [modal, setModal] = useState(false)
  const [notifyStatus, setNotifyStatus] = useState(false)

  return (
    <Box>
      <img
        src={process.env.PUBLIC_URL + 'images/active-listing/top-background.png'}
        style={{
          position: 'absolute',
          top: '134px',
        }}
        alt="backgroundImage"
      />
      <Typography sx={{
        fontSize: '40px',
        fontWeight: '600 !important',
        color: '#2F2F2F',
        paddingTop: '160px',
        paddingBottom: '74px',
        textAlign: 'center',
        backgroundColor: '#F6F8FD',
      }}>
        All Active Self Storage Listings
      </Typography>
      <Container maxWidth="xl" sx={{
        padding: '0px 50px !important',
        position: 'relative',
        top: '-55px'
      }}>
        <Card sx={{
          padding: "30px 36px 34px 34px",
          boxShadow: "0px 5px 50px rgba(1, 31, 109, 0.1)",
          borderRadius: "10px"
        }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormControl>
              <MInput
                label="Location"
                sx={{
                  marginTop: '15px',
                  borderRadius: '10px',
                  height: '60px',
                  width: '418px !important'
                }}
                placeholder="Search location"
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>}
              />
            </FormControl>
            <Stack direction="row" spacing="20px">
              <FormControl>
                <MInput
                  label="Properties"
                  sx={{
                    marginTop: '15px',
                    borderRadius: '10px',
                    height: '60px',
                    width: '200px !important'
                  }}
                  placeholder="Unit Count"
                  startAdornment={
                    <InputAdornment position="start">
                      <HomeWorkOutlinedIcon />
                    </InputAdornment>}
                />
              </FormControl>
              <FormControl>
                <MInput
                  sx={{
                    borderRadius: '10px',
                    height: '60px',
                    width: '200px !important',
                    marginTop: '47px !important'
                  }}
                  placeholder="Occupancy"
                  startAdornment={
                    <InputAdornment position="start">
                      <SupervisorAccountOutlinedIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>
            <MButton
              sx={{
                width: '148px',
                height: '60px',
                borderRadius: '10px',
                marginTop: '47px !important',
                fontSize: '20px',
                fontWeight: '400 !important',
                color: '#000103B2',
                backgroundColor: '#FFCE31',
                '&:hover': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2',
                },
                '&:active': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                },
                '&:focus': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                }
              }}
              startIcon={<SearchIcon />}
            >Search</MButton>
          </Stack>
        </Card>

        <Card sx={{
          backgroundColor: '#F6F8FD',
          borderRadius: '10px',
          marginTop: '30px',
          padding: '0px 34px',
          boxShadow: 'none'
        }}>
          <Stack sx={{ height: '105px' }} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <MButton
                sx={{
                  fontSize: '20px',
                  fontWeight: '500 !important',
                  color: '#000103B2',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  '&:active': {
                    color: '#1849C6 !important',
                  },
                  '&:focus': {
                    color: '#1849C6 !important',
                  }
                }}
                variant="text"
                disableRipple={true}
                startIcon={<DehazeIcon />}
                onClick={() => setView(0)}
              >List View</MButton>
              <MButton
                sx={{
                  ml: '34px',
                  fontSize: '20px',
                  fontWeight: '500 !important',
                  color: '#000103B2',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  '&:active': {
                    color: '#1849C6 !important',
                  },
                  '&:focus': {
                    color: '#1849C6 !important',
                  }
                }}
                variant="text"
                disableRipple={true}
                startIcon={<GridViewRoundedIcon />}
                onClick={() => setView(1)}
              >Grid View</MButton>
            </Box>
            <MButton
              variant="contained"
              sx={{
                width: '309px',
                height: '62.86px',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: '500 !important',
                color: '#FFFFFF',
                backgroundColor: '#1849C6',
              }}
              color="primary"
              startIcon={<NotificationsIcon sx={!notifyStatus ? { color: 'white' } : { color: '#FFCE31' }} />}
              onClick={() => setModal(true)}
            >Get notified of this search</MButton>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>
                <SvgIcon component={SortIcon} inheritViewBox />
                Sort by:
              </Typography>
              <MInput
                sx={{
                  width: '182px !important',
                  height: '55px !important',
                  marginLeft: '15px'
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <KeyboardArrowDownIcon />
                  </InputAdornment>}
                defaultValue="Newest"
              />
            </Stack>
          </Stack>
        </Card>

        <Box sx={{ mt: '30px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{
              color: '#000103B2',
              fontSize: '25px',
              fontWeight: '500 !important',
            }}>34 Listings Found</Typography>
            <Pagination size="large" sx={{
              '& .Mui-selected': {
                backgroundColor: '#FFFFFF',
                color: '#1849C6',
                borderColor: '#1849C6'
              },
            }} count={4} variant="outlined" color="primary" />
          </Stack>
          <Card sx={{
            background: '#FFFFFF',
            border: '1px solid #D9D9D9',
            borderRadius: '20px',
            padding: '20px 40px',
            margin: '30px 0px'
          }}>
            {
              view === 0 ? card1Items.map((item, key) => (
                <LargeCard
                  cardImageURL={item.cardImageURL}
                  key={key}
                />
              )) :
                <Grid container space={2}>
                  {
                    card2Items.map((item, key) => (
                      <Grid item xs={4} sx={{ mb: '20px' }}>
                        <StorageCard
                          cardImageURL={item.cardImageURL}
                          key={key}
                          cardStyle={{
                            margin: 'auto',
                            backgroundColor: '#F9F9F9 !important',
                            boxShadow: 'none'
                          }}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
            }
          </Card>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{
              color: '#000103B2',
              fontSize: '25px',
              fontWeight: '500 !important'
            }}>34 Listings Found</Typography>
            <Pagination size="large" sx={{
              '& .Mui-selected': {
                backgroundColor: '#FFFFFF',
                color: '#1849C6',
                borderColor: '#1849C6'
              },
            }} count={4} variant="outlined" color="primary" />
          </Stack>
        </Box>
      </Container>
      <SearchNotifyModal
        open={modal}
        setOpen={setModal}
        setStatus={() => setNotifyStatus(true)}
      />
    </Box>
  )
}

export default ActiveListing

import React from 'react'
import { Header } from 'containers/Header'
import { Footer } from 'containers/Footer'
import {
  Box,
} from '@mui/material'

const Layout = ({ children }) => {

  return (
    <Box>
      <Header />
      <Box>
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout

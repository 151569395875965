import React from 'react'
import {
  Box,
  Container,
  Typography,
  Card,
  InputAdornment,
  Grid
} from '@mui/material'
import { MButton, MInput } from 'components/CustomMaterial'
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg"
import { ReactComponent as ShapeIcon } from "../../assets/icons/shape1.svg"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom'

const FreeEvaluation = () => {
  const history = useHistory()

  return (
    <Box>
      <img
        src={process.env.PUBLIC_URL + 'images/active-listing/top-background.png'}
        style={{
          position: 'absolute',
          top: '134px',
        }}
        alt="backgroundImage"
      />
      <Box sx={{ backgroundColor: '#F6F8FD', }}>
        <Typography sx={{
          fontSize: '40px',
          fontWeight: '600 !important',
          color: '#2F2F2F',
          paddingTop: '160px',
          textAlign: 'center',
        }}>
          Get Your Self Storage Evaluated
        </Typography>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginTop: '15px',
          textAlign: 'center',
          paddingBottom: '36px'
        }}>
          Let’s help you determine what your self storage is worth
        </Typography>
      </Box>
      <img style={{
        position: 'absolute',
        right: '0',
        bottom: '0%',
      }} src={process.env.PUBLIC_URL + 'images/evaluation/ellipse2.png'} alt="ellipse" />
      <Container maxWidth="xl" sx={{ mt: '100px', mb: '100px' }}>
        <Grid container>
          <Grid item xs={4} sx={{ margin: 'auto' }}>
            <img style={{
              position: 'relative',
              zIndex: '10',
              margin: 'auto !important'
            }}
              src={process.env.PUBLIC_URL + 'images/evaluation/user-image.png'} alt="UserImage" />
            <img style={{
              position: 'absolute',
              left: '10%',
              bottom: '0%',
              zIndex: '0'
            }}
              src={process.env.PUBLIC_URL + 'images/evaluation/rectangle.png'} alt="rectangle" />
            <img style={{
              position: 'absolute',
              left: '33%',
              bottom: '10%'
            }}
              src={process.env.PUBLIC_URL + 'images/evaluation/ellipse1.png'} alt="ellipse" />
          </Grid>
          <Grid item xs={6} sx={{ pl: '60px', mt: '20px', pr: '50px' }}>
            <Typography sx={{
              fontSize: '30px',
              fontWeight: '600 !important',
              color: '#000103B2',
              marginBottom: '20px'
            }}>Have a Self Storage??</Typography>
            <Typography sx={{
              fontSize: '20px',
              fontWeight: '600 !important',
              color: '#000103B2',
              marginBottom: '20px'
            }}>
              Using our platform is the go to place on the internet for anything that
              has to do with Self Storage, <b>we’ll help you determine what your Self
                Storage is worth,</b>
            </Typography>
            <Typography sx={{
              fontSize: '20px',
              fontWeight: '600 !important',
              color: '#000103B2',
              marginBottom: '20px'
            }}>
              kindly share some information about you and your Self Storage with us
              and a dedicated account manager will get in touch
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/evaluation/formbg.png)`,
        backgroundRepeat: 'round',
        paddingTop: '74px',
        paddingBottom: '114px'
      }}>
        <Container maxWidth="lg" >
          <Typography sx={{
            textAlign: 'center',
            color: 'white',
            fontSize: '22px',
            fontWeight: '500 !important',
            padding: '0px 50px 50px 60px'
          }}>
            Let us know about you and your Self Storage, drop some information that
            would help us carry out the evaluation effectively
          </Typography>
          <Card sx={{
            backgroundColor: '#FFFFFFE5 !important',
            border: '1px solid #CDD4E7',
            borderRadius: '30px',
            padding: '53px 70px',
            marginTop: '50px',
            marginBottom: '50px'
          }}>
            <Grid container spacing="100px" sx={{ mb: '70px' }}>
              <Grid item xs={6}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '25px',
                  fontWeight: '600 !important',
                  marginBottom: '10px'
                }}>
                  Your Contact Details
                </Typography>
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="John"
                  startAdornment={
                    <InputAdornment position="start">
                      <UserIcon color="disabled" />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Number"
                  startAdornment={
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Mail Address"
                  startAdornment={
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Full address"
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon />
                    </InputAdornment>}
                />
                <Typography>
                  <InfoIcon />
                  Your information will not be public
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{
                  color: '#000103B2',
                  fontSize: '25px',
                  fontWeight: '600 !important',
                  marginBottom: '10px'
                }}>
                  Self Storage Details
                </Typography>
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Total Units"
                  startAdornment={
                    <InputAdornment position="start">
                      <HomeWorkOutlinedIcon />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Occupancy"
                  startAdornment={
                    <InputAdornment position="start">
                      <SupervisorAccountOutlinedIcon />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Storage square feet"
                  startAdornment={
                    <InputAdornment position="start">
                      <CropFreeOutlinedIcon />
                    </InputAdornment>}
                />
                <MInput
                  sx={{
                    backgroundColor: '#F6F8FD !important',
                    borderRadius: '10px',
                    border: 'none',
                    marginBottom: '20px',
                    width: '100%',
                    color: '#A1A6B1',
                  }}
                  placeholder="Storage location"
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon />
                    </InputAdornment>}
                />
              </Grid>
            </Grid>
            <Container sx={{ textAlign: 'center', }}>
              <MButton
                variant="contained"
                sx={{
                  fontSize: '18px',
                  color: 'white',
                  fontWeight: '600 !important',
                  backgroundColor: '#1849C6',
                  width: '418px',
                  height: '64px',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: '#1849C6 !important',
                    color: 'white',
                  },
                  '&:active': {
                    backgroundColor: '#1849C6 !important',
                    color: '#FFFFFF !important',
                  },
                  '&:focus': {
                    backgroundColor: '#1849C6 !important',
                    color: '#FFFFFF !important',
                  }
                }}
                endIcon={<ShapeIcon />}
                onClick={() => history.push("inquiring")}>
                Send Message
              </MButton>
            </Container>
          </Card>
        </Container>
      </Box>
    </Box>
  )
}

export default FreeEvaluation

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { styled, makeStyles } from '@mui/styles'
import {
  Input, Typography,
} from '@mui/material'

const useStyles = makeStyles(theme => ({
  error: {
    border: '1px solid #FA0101 !important',
  },
  helperText: {
    fontSize: '14px',
    color: '#FA0101',
    margin: '0px',
    textAlign: 'left',
  },
  labelText: {
    fontSize: '20px',
    color: '#000103B2',
    fontWeight: '400',
  },
}))

const InputStyle = styled(Input)(({ theme, styleProps }) => {
  return {
    width: '100%',
    padding: '0px 16px',
    border: '1px solid #EAEAEA',
    borderRadius: '4px',
    color: '#858585',
    fontSize: '16px',
    height: '48px',
    background: 'white',
  }
})

// ---------------------------------------- ------------------------------

const MInput = forwardRef(
  ({ type = 'text', error, label, children, ...other }, ref) => {
    const classes = useStyles()

    return (
      <>
        {
          label &&
          <Typography variant="h6" className={classes.labelText}>{label}</Typography>
        }
        <InputStyle
          ref={ref}
          disableUnderline
          className={error && classes.error}
          {...other}
        >
          {children}
        </InputStyle>
        {
          error &&
          <p className={classes.helperText}>{error}</p>
        }
      </>
    )
  }
)

MInput.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default MInput

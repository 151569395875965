import React from 'react'
import { MButton } from 'components/CustomMaterial'
import {
  Box,
  Typography,
  Container,
  Stack
} from '@mui/material'
import { useHistory } from 'react-router-dom'

const ViewMoreListing = () => {
  const history = useHistory()

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/image.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
        backgroundColor: '#E8EFFF',
        borderBottomRightRadius: '300px',
        backgroundSize: '679px',
      }}>
        <Container>
          <Box sx={{ textAlign: 'center', mb: '356px' }}>
            <MButton
              variant="contained"
              sx={{
                width: '364px',
                height: '80px',
                borderRadius: '10px',
                color: '#000103B2 !important',
                fontSize: '20px',
                fontWeight: '600 !important',
                backgroundColor: '#FFCE31',
                marginTop: '32px',
                '&:hover': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                },
                '&:active': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                },
                '&:focus': {
                  backgroundColor: '#FFCE31 !important',
                  color: '#000103B2 !important',
                }
              }}
              onClick={() => history.push('active-listing')}
            >View More Listings</MButton>
          </Box>
          <Box>
            <Typography sx={{
              fontWeight: '700px',
              fontSize: '35px',
              color: '#021850'
            }}
            >Do you have a Self Storage for sale?</Typography>
            <Typography sx={{
              fontWeight: '400px',
              fontSize: '18px',
              color: '#000103B2',
              marginBottom: '40px',
              marginTop: '20px',
              width: '45%'
            }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              tortor sapien sagittis maecenas tincidunt. Quis pretium ornare id
              diam amet proin scelerisque nunc.
            </Typography>
            <MButton
              variant="contained"
              sx={{
                width: '253px',
                height: '60px',
                fontWeight: '600px',
                fontSize: '18px',
                color: '#FFFFFF',
                backgroundColor: '#1849C6',
                borderRadius: '10px',
              }}
              onClick={() => history.push('sell-yourself-storage')}
            >Sell Your Self Storage</MButton>
          </Box>
        </Container>
        <Box sx={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/ellipse7.png)`,
          height: '240px',
          backgroundPosition: 'bottom left',
          backgroundRepeat: 'no-repeat'
        }}></Box>
      </Box>
      <Box>
        <Box>
          <img style={{
            position: 'absolute',
            bottom: '-100px'
          }}
            src={`${process.env.PUBLIC_URL}/images/background1.png`} alt="background" />
        </Box>
        <Container maxWidth="xl" sx={{mt: '116px'}}>
          <Stack direction="row" spacing="37px">
            <img
              src={`${process.env.PUBLIC_URL}/images/image1.png`}
              alt="buy a sell storage"
            />
            <Box sx={{ mt: '150px !important' }}>
              <Typography sx={{
                fontWeight: '700px',
                fontSize: '35px',
                color: '#021850'
              }}>
                Do you want to buy a Self Storage?
              </Typography>
              <Typography sx={{
                fontWeight: '400px',
                fontSize: '18px',
                color: '#000103B2',
                marginBottom: '40px',
                marginTop: '20px',
                width: '65%'
              }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                tortor sapien sagittis maecenas tincidunt. Quis pretium ornare id
                diam amet proin scelerisque nunc.
              </Typography>
              <MButton
                variant="contained"
                sx={{
                  width: '253px',
                  height: '60px',
                  fontWeight: '600px',
                  fontSize: '18px',
                  color: '#FFFFFF',
                  backgroundColor: '#1849C6',
                  borderRadius: '10px',
                }}
                onClick={() => history.push('sell-yourself-storage')}
              >Buy a Self Storage</MButton>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default ViewMoreListing

import React from 'react'
import {
  Box,
  Container,
  Typography,
  Card,
  InputAdornment,
  Grid,
} from '@mui/material'
import { MButton, MInput } from 'components/CustomMaterial'
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg"
import { ReactComponent as ShapeIcon } from "../../assets/icons/shape1.svg"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom'

const SellYourSelfStorage = () => {
  const history = useHistory()

  return (
    <Box>
      <img
        src={process.env.PUBLIC_URL + 'images/active-listing/top-background.png'}
        style={{
          position: 'absolute',
          top: '134px',
        }}
        alt="backgroundImage"
      />
      <Box sx={{ backgroundColor: '#F6F8FD !important', }}>
        <Typography sx={{
          fontSize: '40px',
          fontWeight: '600 !important',
          color: '#2F2F2F',
          paddingTop: '160px',
          textAlign: 'center',
        }}>
          Sell Your Self Storage With Ease
        </Typography>
        <Typography sx={{
          color: '#000103B2',
          fontSize: '20px',
          fontWeight: '400 !important',
          marginTop: '15px',
          textAlign: 'center',
          paddingBottom: '36px'
        }}>
          Let’s help you get the most out of your hard work
        </Typography>
      </Box>
      <Container maxWidth="lg" >
        <Card sx={{
          backgroundColor: '#FFFFFFE5 !important',
          border: '1px solid #CDD4E7',
          borderRadius: '30px',
          padding: '53px 70px',
          marginTop: '50px',
          marginBottom: '50px',
          boxShadow: 'none'
        }}>
          <Grid container spacing="100px" sx={{ mb: '70px' }}>
            <Grid item xs={6}>
              <Typography sx={{
                color: '#000103B2',
                fontSize: '25px',
                fontWeight: '600 !important',
                marginBottom: '50px'
              }}>
                Your Contact Details
              </Typography>
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="John"
                startAdornment={
                  <InputAdornment position="start">
                    <UserIcon color="disabled" />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Number"
                startAdornment={
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Mail Address"
                startAdornment={
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Full address"
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>}
              />
              <Typography>
                <InfoIcon />
                Your information will not be public
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{
                color: '#000103B2',
                fontSize: '25px',
                fontWeight: '600 !important',
                marginBottom: '50px'
              }}>
                Self Storage Details
              </Typography>
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Total Units"
                startAdornment={
                  <InputAdornment position="start">
                    <HomeWorkOutlinedIcon />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Occupancy"
                startAdornment={
                  <InputAdornment position="start">
                    <SupervisorAccountOutlinedIcon />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Storage square feet"
                startAdornment={
                  <InputAdornment position="start">
                    <CropFreeOutlinedIcon />
                  </InputAdornment>}
              />
              <MInput
                sx={{
                  backgroundColor: '#F6F8FD !important',
                  borderRadius: '10px',
                  border: 'none',
                  marginBottom: '20px',
                  width: '100%',
                  color: '#A1A6B1',
                }}
                placeholder="Storage location"
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>}
              />
            </Grid>
          </Grid>
          <Container sx={{ textAlign: 'center', }}>
            <MButton
              variant="contained"
              sx={{
                fontSize: '18px',
                color: 'white',
                fontWeight: '600 !important',
                backgroundColor: '#1849C6',
                width: '418px',
                height: '64px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#1849C6 !important',
                  color: 'white',
                },
                '&:active': {
                  backgroundColor: '#1849C6 !important',
                  color: '#FFFFFF !important',
                },
                '&:focus': {
                  backgroundColor: '#1849C6 !important',
                  color: '#FFFFFF !important',
                }
              }}
              endIcon={<ShapeIcon />}
              onClick={() => history.push('inquiring')}
            >
              Send Message
            </MButton>
          </Container>
        </Card>
      </Container>
    </Box>
  )
}

export default SellYourSelfStorage

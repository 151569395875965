import React, { Fragment } from 'react'
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Stack
} from '@mui/material'
import { Logo } from 'components/Logo'
import { MButton } from 'components/CustomMaterial'
import useStyles from './styles'
import { useHistory } from 'react-router-dom'

const NavButtons = [
  { text: 'Active Listening', to: '/active-listing' },
  { text: 'Sell your Self Storage', to: '/sell-yourself-storage' },
  { text: 'Free Evaluation', to: '/free-evaluation' },
  { text: 'Learn', to: '/learn' },
  { text: 'Contact', to: '/contact', isContact: true }
]

const Header = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar sx={{ backgroundColor: "#1849C6" }}>
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar}>
          <Box>
            <Logo />
          </Box>
          <Stack direction="row" spacing={4}>
            {
              NavButtons.map((item, key) => {
                return (
                  <Fragment key={key}>
                    {
                      !item.isContact ?
                        <MButton
                          sx={{
                            height: '36px',
                            color: '#fff',
                            fontSize: '20px',
                            fontWeight: '400',
                            alignItems: 'baseline',
                            '&:active': {
                              color: '#FFCE31 !important',
                            },
                            '&:focus': {
                              color: '#FFCE31 !important',
                            }
                          }}
                          disableRipple={true}
                          onClick={() => history.push(item.to)}
                        > {item.text} </MButton> :
                        <MButton
                          sx={{
                            backgroundColor: '#FFCE31',
                            color: '#021850',
                            fontSize: '18px',
                            fontWeight: "500 !important",
                            padding: "8px 18px",
                            borderRadius: '10px',
                            '&:hover': {
                              backgroundColor: '#FFCE31 !important',
                              color: '#021850',
                            },
                            '&:active': {
                              backgroundColor: '#FFCE31 !important',
                              color: '#021850 !important',
                            },
                            '&:focus': {
                              backgroundColor: '#FFCE31 !important',
                              color: '#021850 !important',
                            }
                          }}
                          onClick={() => history.push(item.to)}
                          variant="contained"
                          disableRipple={true}
                        > {item.text} </MButton>
                    }
                  </Fragment>
                )
              })
            }
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header

import React from 'react'
import {
  Box,
  Stack,
  Typography,
  Container
} from '@mui/material'
import { MButton } from 'components/CustomMaterial'
import { useHistory } from 'react-router-dom'

const HeroSection = () => {
  const history = useHistory()

  return (
    <Box sx={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero_background.jpg)`,
      backgroundRepeat: 'round',
      backgroundSize: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      <Container maxWidth="md" >
        <Box>
          <Typography variant="h2" sx={{
            fontSize: '60px',
            fontWeight: '800',
            lineHeight: '75px',
            color: 'white',
            padding: '0px 100px'
          }}>
            Self Storage Business Buy. Sell. Learn.
          </Typography>
          <Typography sx={{
            fontSize: '20px',
            fontWeight: '500',
            lineHeiight: '33px',
            color: 'white',
            padding: '0px 100px',
            marginTop: '10px'
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
            sagittis, aliquam non tortor blandit lobortis. blandit
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="center" spacing="20px" sx={{ mt: '52px' }}>
          <MButton variant="contained"
            sx={{
              backgroundColor: '#FFCE31',
              borderRadius: '10px',
              height: '60px',
              width: '251px',
              fontSize: '18px',
              fontWeight: '500 !important',
              lineHeight: '20px',
              color: '#021850',
              '&:hover': {
                backgroundColor: '#FFCE31 !important',
              },
              '&:active': {
                backgroundColor: '#FFCE31 !important',
              }
            }}
            onClick={() => history.push('sell-yourself-storage')}
          >Sell Your Self Storage</MButton>
          <MButton variant="contained"
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
              height: '60px',
              width: '251px',
              fontSize: '16px',
              fontWeight: '500 !important',
              lineHeight: '20px',
              color: '#000103B2',
              '&:hover': {
                backgroundColor: '#FFFFFF !important',
              },
              '&:active': {
                backgroundColor: '#FFFFFF !important',
              }
            }}
            onClick={() => history.push('active-listing')}>Active Self Storage Listings</MButton>
        </Stack>
      </Container>
    </Box>
  )
}

export default HeroSection
